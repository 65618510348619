import React from "react";
import { createRoot } from "react-dom/client";

import "normalize.css";

import {
	AllCommunityModule,
	ClientSideRowModelModule,
	CsvExportModule,
	InfiniteRowModelModule,
	ModuleRegistry,
	provideGlobalGridOptions,
} from "ag-grid-community";
import {
	CellSelectionModule,
	ClipboardModule,
	ColumnMenuModule,
	ColumnsToolPanelModule,
	ContextMenuModule,
	ExcelExportModule,
	FiltersToolPanelModule,
	GroupFilterModule,
	LicenseManager,
	PivotModule,
	RichSelectModule,
	RowGroupingModule,
	RowGroupingPanelModule,
	ServerSideRowModelModule,
	SetFilterModule,
	StatusBarModule,
	TreeDataModule,
} from "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "/src/theme/ag-grid-theme-builder.css"; // Optional Theme applied to the grid
import jwt from "jsonwebtoken";

import * as Sentry from "@sentry/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";
import { ApolloProvider } from "@apollo/client";
import "./services/wdyr";
import "@services/objectsUtils";
import setTokenFromUrl from "@services/setTokenFromUrl";
import Highcharts from "highcharts";
import { client } from "../config/apollo";
import { Main } from "@src/main";
import GlobalContext from "@globalContext";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

ModuleRegistry.registerModules([
	ClientSideRowModelModule,
	AllCommunityModule,
	ServerSideRowModelModule,
	ColumnsToolPanelModule,
	RowGroupingPanelModule,
	RowGroupingModule,
	GroupFilterModule,
	TreeDataModule,
	PivotModule,
	FiltersToolPanelModule,
	SetFilterModule,
	ClipboardModule,
	ColumnMenuModule,
	ContextMenuModule,
	CellSelectionModule,
	StatusBarModule,
	ExcelExportModule,
	CsvExportModule,
	InfiniteRowModelModule,
	RichSelectModule,
]);
LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE_KEY || "no_license_key");
provideGlobalGridOptions({
	theme: "legacy",
});

window.Highcharts = Highcharts;
setTokenFromUrl();
if (process.env.ENVIRONMENT === "production" || process.env.ENVIRONMENT === "preprod") {
	Sentry.init({
		dsn: "https://773b2610ff4d5645eb661a3bb8c5963e@o460574.ingest.us.sentry.io/4507254228123648",
		ignoreErrors: [
			// Error generated by a bug in autofill library from browser
			// https://github.com/getsentry/sentry/issues/5267
			/Blocked a frame with origin/,
		],
	});
	const scope = Sentry.getCurrentScope();
	if (scope) {
		scope.setUser(jwt.decode(localStorage.jwtToken));
	}
}

if (process.env.NODE_ENV !== "production") {
	// Adds messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

document.title =
	process.env.ENVIRONMENT === "development"
		? "Givemefive Dev"
		: process.env.ENVIRONMENT === "preprod"
			? "Givemefive Preprod"
			: "Givemefive";

const rootElement = document.getElementById("mount");
const root = createRoot(rootElement);

root.render(
	<I18nextProvider i18n={i18n}>
		<ApolloProvider connectToDevTools={true} client={client()}>
			<GlobalContext.Provider
				value={{
					light: false,
				}}
			>
				<Main />
			</GlobalContext.Provider>
		</ApolloProvider>
	</I18nextProvider>
);
